import {request} from '@/utils/request'

// 列表
export function getBuying(data) {
  return request('GET','/shop/backendPurchase', data, true)
}

// 添加
export function postBuying(data) {
  return request('POST','/shop/backendPurchase', data, true)
}

// 编辑
export function putBuying(id, data) {
  return request('PUT','/shop/backendPurchase/' + id, data, true)
}

// 查看
export function getBuyingInfo(id) {
  return request('GET','/shop/backendPurchase/' + id, '', true)
}

// 关闭
export function postBuyingCancel(id) {
  return request('POST','/shop/backendPurchase/' + id, '', true)
}

// 单位
export function getUnit(data) {
  return request('GET','/shop/backendUnit', data, true)
}

// 列表
export function getSupply(data) {
  return request('GET','/shop/backendProvision', data, true)
}

// 关闭
export function postSupplyCancel(id) {
  return request('POST','/shop/backendProvision/' + id, '', true)
}

// 添加
export function postSupply(data) {
  return request('POST','/shop/backendProvision', data, true)
}

// 详情
export function getSupplyInfo(id) {
  return request('GET','/shop/backendProvision/' + id, '', true)
}

// 修改
export function putSupply(id, data) {
  return request('PUT','/shop/backendProvision/' + id, data, true)
}

// 删除
export function delSupply(id) {
  return request('DELETE','/shop/backendProvision/' + id, '', true)
}

// 未读
export function getBuyingRead() {
  return request('GET','/shop/backendPurchase/noRead', '', true)
}
